import { gsap } from 'gsap';
import AOS    from 'aos';

(function ($) {
	$(() => {
		AOS.init({
			once : true,
		});
		// Parallax
		const parallaxSection = $('.section-parallax');
		if (parallaxSection.length > 0) {
			parallaxSection.mousemove(function (e) {
				const wx   = $(window).width();
				const wy   = $(window).height();
				const x    = e.pageX - this.offsetLeft;
				const y    = e.pageY - this.offsetTop;
				const newx = x - wx / 2;
				const newy = y - wy / 2;

				$('.parallax-box__trigger', $(this)).each(function () {
					let speed = $(this).attr('data-parallax-speed') / 10;
					if ($(this).attr('data-revert')) speed *= -1;

					gsap.to(
						$(this),
						{
							duration : 1,
							x        : 1 - newx * speed,
							y        : 1 - newy * speed,
						},
					);
				});
			});
		}
	});
	// eslint-disable-next-line no-undef
}(jQuery));
